<template>
  <div class="px-5 pt-5 pb-10 flex flex-col justify-between h-full overflow-scroll">
    <div>
      <router-link :to="{ name: 'StepThree' }">
       <BackIcon class="mb-10" />
      </router-link>
    </div>
    <div class="">
      <div class="mb-16 flex justify-center">
        <StepFiveIllustration />
      </div>
      <div class="flex flex-col items-center mb-6">
        <span>Here is the thing:</span>
        <h3 class="text-lg"> Feeling good is a habit</h3>
      </div>

      <div class="flex space-x-1 items-center justify-center mb-8">
        <div v-for="item in items" :key="item.id"
          class=" rounded-full h-2 w-2 bg-customPurple"></div>
      </div>
      <router-link class="w-10/12" :to="{ name: 'StepFive' }">
        <MainButton text="Yeah" class="h-14" />
      </router-link>
    </div>
  </div>
</template>

<script>
import BackIcon from "@/components/svg/BackIcon.vue";
import MainButton from "@/components/shared/MainButton.vue";
import StepFiveIllustration from "@/components/svg/onBoarding/StepFiveIllustration.vue";

export default {
  name: "StepFour",

  components: {
    BackIcon,
    MainButton,
    StepFiveIllustration,
  },
  data() {
    return {
      items: ["Item1", "Item2", "Item3", "Item4", "Item5", "Item6"],
    };
  },
};
</script>

<style>
</style>