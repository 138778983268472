<template>
 <svg width="283" height="297" viewBox="0 0 283 297" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.1" d="M147.871 120.472C132.222 123.523 116.204 121.671 100.737 119.163C85.271 116.654 69.7926 113.487 53.9303 113.949C43.7278 114.246 32.5218 116.568 25.9662 123.929C19.6568 131.025 19.3592 140.772 20.0384 149.603C20.548 156.247 21.5435 163.13 25.6521 168.502C28.5026 172.232 32.6555 174.957 35.8948 178.425C47.1627 190.443 45.4929 208.899 39.9648 224.493C37.3753 231.809 34.059 238.99 32.4939 246.493C30.9289 253.996 31.2846 262.102 35.6504 268.37C39.9816 274.586 47.6855 278.206 55.5739 280.179C71.6001 284.187 88.9471 282.353 105.674 279.703C142.69 273.842 179.015 264.155 215.244 254.491C228.652 250.913 242.116 247.317 254.886 242.003C261.978 239.052 269.14 235.326 273.399 229.368C278.804 221.807 278.127 211.296 271.823 204.871C261.244 194.092 239.392 195.904 230.892 183.668C226.22 176.934 227.311 167.962 229.975 160.08C235.695 143.174 248.035 126.631 244.905 109.564C242.754 97.8403 232.655 87.9586 219.952 85.1393C206.635 82.1638 190.201 86.7721 183.596 98.3509C176.79 110.282 161.688 117.776 147.871 120.472Z" fill="#7E6A97"/>
<g clip-path="url(#clip0)">
<path d="M249.862 279.845H156.899V279.086H250L249.862 279.845Z" fill="#3F3D56"/>
<path d="M194.727 264.968H189.677V275.785H194.727V264.968Z" fill="#FBBEBE"/>
<path d="M234.042 265.328H228.992V276.145H234.042V265.328Z" fill="#FBBEBE"/>
<path d="M234.583 270.556L228.451 269.835L212.581 196.641L195.268 270.196L189.136 269.475C188.124 236.192 191.081 202.897 193.645 175.188L230.255 172.123C237.152 199.427 237.435 233.409 234.583 270.556Z" fill="#2F2E41"/>
<path d="M230.775 176.453C230.775 176.453 222.248 185.348 227.068 187.571C231.888 189.795 234.853 177.565 234.853 177.565L230.775 176.453Z" fill="#FBBEBE"/>
<path d="M187.132 177.174C187.132 177.174 178.605 186.069 183.425 188.293C188.244 190.516 191.21 178.286 191.21 178.286L187.132 177.174Z" fill="#FBBEBE"/>
<path d="M213.122 112.81C218.699 112.81 223.221 108.29 223.221 102.714C223.221 97.1386 218.699 92.6186 213.122 92.6186C207.544 92.6186 203.022 97.1386 203.022 102.714C203.022 108.29 207.544 112.81 213.122 112.81Z" fill="#FBBEBE"/>
<path d="M224.844 123.807L206.81 125.61C208.477 118.109 208.881 111.1 206.81 105.058H219.434C218.748 110.093 221.311 116.713 224.844 123.807Z" fill="#FBBEBE"/>
<path d="M229.894 176.089C223.155 184.192 208.994 181.483 192.743 175.728C197.354 160.681 196.639 144.805 192.495 128.397C192.27 127.515 192.363 126.582 192.759 125.762C193.155 124.942 193.828 124.289 194.659 123.917L207.892 118.038L212.941 119.12L221.598 116.957L233.886 119.469C234.885 119.674 235.778 120.229 236.402 121.035C237.027 121.841 237.342 122.844 237.291 123.862C236.263 144.002 234.445 162.641 229.894 176.089Z" fill="#E6E6E6"/>
<path d="M233.501 182.579L230.254 177.171L236.386 154.095L233.14 119.481C235.234 119.69 237.207 120.559 238.774 121.963C240.341 123.367 241.421 125.233 241.858 127.291L246.783 150.5C247.525 153.998 247.079 157.644 245.516 160.861L233.501 182.579Z" fill="#E6E6E6"/>
<path d="M191.3 178.613L183.906 179.875L188.621 133.736C188.667 131.434 189.39 129.196 190.7 127.302C192.011 125.408 193.85 123.941 195.989 123.086L198.334 153.554L191.3 178.613Z" fill="#E6E6E6"/>
<path d="M192.807 279.565L179.196 281.954C178.579 282.063 177.943 281.977 177.375 281.709C176.926 281.497 176.536 281.179 176.239 280.781C175.942 280.383 175.748 279.918 175.672 279.428C175.596 278.937 175.643 278.435 175.806 277.967C175.97 277.498 176.246 277.077 176.61 276.74L182.441 271.347L183.961 268.692C184.388 267.923 184.998 267.271 185.737 266.793C186.476 266.315 187.321 266.026 188.198 265.952C190.797 265.771 191.655 267.749 191.111 271.462C193.086 272.913 193.398 272.69 194.727 270.737L195.33 276.858C195.261 277.522 194.977 278.145 194.522 278.634C194.067 279.122 193.464 279.449 192.807 279.565Z" fill="#2F2E41"/>
<path d="M232.122 279.565L218.512 281.954C217.894 282.063 217.258 281.977 216.691 281.709C216.242 281.497 215.852 281.179 215.555 280.781C215.258 280.383 215.063 279.918 214.987 279.428C214.912 278.937 214.958 278.435 215.121 277.967C215.285 277.498 215.561 277.077 215.926 276.74L221.756 271.347L223.276 268.692C223.703 267.923 224.313 267.271 225.052 266.793C225.791 266.315 226.636 266.026 227.513 265.952C230.112 265.771 230.97 267.749 230.427 271.462C232.401 272.913 232.713 272.69 234.042 270.737L234.645 276.858C234.576 277.522 234.292 278.145 233.837 278.634C233.382 279.122 232.78 279.449 232.122 279.565Z" fill="#2F2E41"/>
<path d="M205.104 96.962C209.656 94.3585 214.214 96.0219 218.78 103.126L219.714 110.693C226.567 104.203 226.22 95.3457 218.535 91.7433C217.78 91.3892 217.047 90.9864 216.225 91.1287C214.6 90.2568 212.703 90.0376 210.922 90.516C209.141 90.9943 207.609 92.1341 206.64 93.7027C205.22 93.9485 203.185 96.7685 203.258 98.2072L205.104 96.962Z" fill="#2F2E41"/>
<path d="M218.352 104.878C219.049 104.878 219.614 104.151 219.614 103.255C219.614 102.359 219.049 101.633 218.352 101.633C217.655 101.633 217.089 102.359 217.089 103.255C217.089 104.151 217.655 104.878 218.352 104.878Z" fill="#FBBEBE"/>
<path d="M67.6101 180.282H88.6263C88.6263 182.481 87.7523 184.59 86.1965 186.145C84.6407 187.701 82.5306 188.574 80.3304 188.574H75.906C73.7058 188.574 71.5957 187.701 70.0399 186.145C68.4841 184.59 67.6101 182.481 67.6101 180.282H67.6101Z" fill="#3F3D56"/>
<path d="M93.5367 161.714V154.008C93.5367 144.696 103.668 135.888 110.24 129.604C119.504 120.751 124.374 108.857 124.374 94.924C124.374 82.6827 119.518 70.941 110.871 62.2732C102.224 53.6054 90.4919 48.7187 78.2465 48.6847C66.001 48.6506 54.2417 53.4719 45.5467 62.0915C36.8518 70.7112 31.9307 82.4257 31.8625 94.6667C31.8622 94.7525 31.8622 94.8382 31.8625 94.924C31.8625 108.359 36.941 121.059 45.9962 129.604C52.533 135.772 62.6996 144.599 62.6996 154.008V161.714" fill="#7E6A97"/>
<path d="M93.5369 162.796C93.3948 162.796 93.2541 162.768 93.1228 162.714C92.9916 162.659 92.8723 162.58 92.7718 162.479C92.6713 162.379 92.5916 162.259 92.5372 162.128C92.4828 161.997 92.4549 161.856 92.4549 161.714V154.008C92.4549 144.948 101.393 136.491 107.919 130.316C108.46 129.804 108.987 129.306 109.492 128.822C118.52 120.195 123.292 108.473 123.292 94.924C123.292 69.6019 103.449 49.7662 78.1184 49.7662C78.0759 49.766 78.0333 49.766 77.9908 49.766C66.0699 49.7794 54.6391 54.5106 46.1979 62.9251C37.7566 71.3397 32.9916 82.7531 32.9447 94.6698V94.9217C32.9447 108.191 37.9725 120.545 46.7391 128.817C47.1833 129.236 47.6445 129.668 48.1181 130.111C54.7285 136.298 63.7818 144.771 63.7818 154.008V161.714C63.7818 162.001 63.6678 162.276 63.4649 162.479C63.262 162.682 62.9868 162.796 62.6998 162.796C62.4128 162.796 62.1376 162.682 61.9346 162.479C61.7317 162.276 61.6177 162.001 61.6177 161.714V154.008C61.6177 145.709 52.9603 137.607 46.6391 131.69C46.1632 131.245 45.6998 130.811 45.2535 130.39C36.0558 121.711 30.7805 108.784 30.7805 94.924V94.6645C30.8531 68.6878 52.0196 47.6026 77.9877 47.6026C78.0338 47.6026 78.0754 47.6026 78.1213 47.6028C84.3467 47.5704 90.5168 48.7724 96.2745 51.1391C102.032 53.5058 107.263 56.9902 111.665 61.3909C116.067 65.7916 119.553 71.0211 121.92 76.777C124.287 82.5329 125.489 88.7008 125.456 94.924C125.456 109.078 120.453 121.34 110.988 130.386C110.48 130.871 109.951 131.372 109.406 131.888C103.166 137.792 94.619 145.879 94.619 154.008V161.714C94.619 161.856 94.591 161.997 94.5367 162.128C94.4823 162.259 94.4026 162.379 94.3021 162.479C94.2016 162.58 94.0823 162.659 93.951 162.714C93.8198 162.768 93.6791 162.796 93.5369 162.796Z" fill="#3F3D56"/>
<path d="M88.3972 176.676H67.8391C67.3608 176.676 66.9021 176.486 66.5639 176.148C66.2256 175.81 66.0356 175.351 66.0356 174.873C66.0356 174.395 66.2256 173.936 66.5639 173.598C66.9021 173.26 67.3608 173.07 67.8391 173.07H88.3972C88.8755 173.07 89.3342 173.26 89.6724 173.598C90.0106 173.936 90.2006 174.395 90.2006 174.873C90.2006 175.351 90.0106 175.81 89.6724 176.148C89.3342 176.486 88.8755 176.676 88.3972 176.676Z" fill="#7E6A97"/>
<path d="M30.2901 52.4279C29.8541 52.4284 29.4329 52.2703 29.1049 51.9832L13.6172 38.4688C13.4386 38.3132 13.2923 38.1239 13.1869 37.9117C13.0815 37.6996 13.0189 37.4688 13.0027 37.2324C12.9866 36.9961 13.0172 36.7589 13.0928 36.5344C13.1684 36.3099 13.2875 36.1025 13.4434 35.924C13.5992 35.7456 13.7887 35.5996 14.001 35.4944C14.2133 35.3892 14.4443 35.3268 14.6807 35.3109C14.9172 35.295 15.1544 35.3258 15.3789 35.4016C15.6034 35.4774 15.8108 35.5966 15.9892 35.7526L31.4768 49.267C31.7534 49.5084 31.9497 49.8284 32.0395 50.1843C32.1292 50.5402 32.1082 50.9149 31.9792 51.2586C31.8502 51.6022 31.6194 51.8982 31.3176 52.1072C31.0157 52.3162 30.6573 52.4281 30.2901 52.4279H30.2901Z" fill="#E6E6E6"/>
<path d="M123.377 52.4279C123.009 52.4281 122.651 52.3162 122.349 52.1072C122.047 51.8982 121.816 51.6022 121.687 51.2585C121.558 50.9149 121.537 50.5401 121.627 50.1843C121.717 49.8284 121.913 49.5084 122.19 49.267L137.677 35.7526C138.038 35.4387 138.508 35.2807 138.985 35.3132C139.462 35.3458 139.906 35.5663 140.221 35.9262C140.535 36.2862 140.694 36.7562 140.661 37.233C140.629 37.7097 140.409 38.1542 140.049 38.4688L124.562 51.9832C124.234 52.2703 123.813 52.4284 123.377 52.4279Z" fill="#E6E6E6"/>
<path d="M93.5368 170.277H62.6997C62.2214 170.277 61.7627 170.087 61.4245 169.749C61.0862 169.411 60.8962 168.952 60.8962 168.474C60.8962 167.996 61.0862 167.537 61.4245 167.199C61.7627 166.861 62.2214 166.671 62.6997 166.671H93.5368C94.0152 166.671 94.4739 166.861 94.8121 167.199C95.1503 167.537 95.3403 167.996 95.3403 168.474C95.3403 168.952 95.1503 169.411 94.8121 169.749C94.4739 170.087 94.0152 170.277 93.5368 170.277Z" fill="#7E6A97"/>
<path d="M78.1181 163.517C77.8813 163.517 77.6468 163.47 77.428 163.38C77.2092 163.289 77.0104 163.156 76.8429 162.989C76.6754 162.822 76.5426 162.623 76.452 162.404C76.3613 162.185 76.3147 161.951 76.3147 161.714V120.613C76.3147 120.134 76.5047 119.676 76.8429 119.338C77.1811 119 77.6398 118.81 78.1181 118.81C78.5965 118.81 79.0552 119 79.3934 119.338C79.7316 119.676 79.9216 120.134 79.9216 120.613V161.714C79.9216 161.951 79.875 162.185 79.7843 162.404C79.6937 162.623 79.5609 162.822 79.3934 162.989C79.2259 163.156 79.0271 163.289 78.8083 163.38C78.5895 163.47 78.355 163.517 78.1181 163.517Z" fill="white"/>
<path d="M78.1182 122.415C72.2912 122.415 65.1371 117.146 64.8354 116.921C64.6454 116.78 64.4851 116.603 64.3636 116.399C64.2422 116.196 64.1619 115.971 64.1275 115.737C64.0931 115.503 64.1052 115.264 64.1631 115.034C64.221 114.805 64.3235 114.589 64.4649 114.399C64.6062 114.209 64.7836 114.049 64.987 113.927C65.1903 113.806 65.4155 113.726 65.6498 113.691C65.8842 113.657 66.123 113.669 66.3526 113.727C66.5823 113.785 66.7983 113.887 66.9883 114.028C68.7806 115.358 74.276 118.81 78.1182 118.81C81.9604 118.81 87.4558 115.358 89.2491 114.028C89.4391 113.886 89.6552 113.784 89.8848 113.726C90.1145 113.668 90.3533 113.656 90.5876 113.691C90.8219 113.725 91.0472 113.806 91.2504 113.927C91.4537 114.049 91.6311 114.209 91.7724 114.399C91.9137 114.589 92.0162 114.805 92.074 115.034C92.1318 115.264 92.1438 115.503 92.1093 115.737C92.0748 115.971 91.9945 116.196 91.873 116.4C91.7515 116.603 91.5911 116.78 91.401 116.921C91.0994 117.146 83.9453 122.415 78.1182 122.415Z" fill="white"/>
<path d="M78.1181 34.432C77.6398 34.432 77.1811 34.242 76.8429 33.9039C76.5047 33.5659 76.3147 33.1073 76.3147 32.6292V1.80281C76.3147 1.32468 76.5047 0.866126 76.8429 0.528032C77.1811 0.189939 77.6398 0 78.1181 0C78.5965 0 79.0552 0.189939 79.3934 0.528032C79.7316 0.866126 79.9216 1.32468 79.9216 1.80281V32.6292C79.9216 33.1073 79.7316 33.5659 79.3934 33.9039C79.0551 34.242 78.5964 34.432 78.1181 34.432Z" fill="#E6E6E6"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="237" height="282" fill="white" transform="translate(13)"/>
</clipPath>
</defs>
</svg>


</template>

<script>
export default {
name:"StepFiveIllustration"
}
</script>

<style>

</style>